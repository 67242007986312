import React from "react";
import { Link } from "react-router-dom";
import CountUp from 'react-countup';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import aboutpic from "../assets/img/home/about (2).png";

const About_Bfc_Publications=()=>
{
    return(

        <>
        
        
        <div className="container">
        <h1>ABOUT BFC PUBLICATIONS</h1>         
         <div className="row align-items-center py-2">
         <div className="col-md-6">
                <p className="text-justify">
                BFC Publications has managed to bring together a group of young and dynamic professionals, all experts in their respective fields. The sole purpose of doing so is to build a platform that does justice to those who are passionate about telling stories but don't have the means to get them published by a self book publisher in India.
                </p>
                <p className="text-justify">
                What most budding authors fail to realise is that a book is rated not just on how well it is written, but also on the response it garners. This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors, but also to educate them about the nuances of self- publishing in India.
                </p>
                <Link to="/about" className="btn btn-custom">Read More</Link>
              </div>
                <div className="col-md-6">
                    <div className="img-box1">
                        <div className="img1"><img src={aboutpic} alt="About" className="img-fluid"/></div>
                        
                       
                        <div className="year-counter">
                        <AnimationOnScroll animateIn="animate__zoomIn animate__delay-0.2s">
                            <h3 className="year-counter_number "><span className="counter-number text-poppins"><CountUp end={15} delay={1}/>+</span></h3>
                            <p className="year-counter_text text-rale">Years Experience</p>
                            </AnimationOnScroll>
                        </div>
                       
                    </div>
                </div>
               
            </div>
        </div>
        
        </>
    )
}

export default About_Bfc_Publications