import React from "react";
import CountUp from 'react-countup';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import whyus1 from "../assets/img/home/1.png";
import whyus2 from "../assets/img/home/1.jpg";
import whyus3 from "../assets/img/home/2.png";
const Why_Us =()=>

{
    return(


        <>
        
        <div className="container">
       
        <h1>WHY US</h1>
       
        <div class="space">
           <div className="row">
          

       <div className="col-md-6">
       <AnimationOnScroll animateIn="animate__fadeInLeft animate__delay-0.2s" className="ani-f">
       <img src={whyus1} alt="" className="img-fluid why-img1"  />
       </AnimationOnScroll>
       <div className="img-box1 why-img2">
                        <div className="img1"><img src={whyus2} alt="About" className="img-fluid w-90"/></div>
                      
                        <div className="year-counter why">
                        <AnimationOnScroll animateIn="animate__zoomIn animate__delay-0.2s">
                            <h3 className="year-counter_number "><span className="counter-number text-poppins"><CountUp end={2} delay={1}/>K+</span></h3>
                            <p className="year-counter_text text-rale">Project</p>
                            </AnimationOnScroll>
                        </div>
                        <div className="year-counter why counter2">
                        <AnimationOnScroll animateIn="animate__zoomIn animate__delay-0.2s">
                            <h3 className="year-counter_number "><span className="counter-number text-poppins"><CountUp end={5} delay={1}/>K+</span></h3>
                            <p className="year-counter_text text-rale">client</p>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                
           
            <div className="col-md-6  pt-5 mt-2">                   
           <p className="text-justify">If online comparisons are to be believed, even the most prominent eBook publishers in India have been struggling to match our package prices and quality. The sheer reasonability of our pricing, coupled with our persistence to deliver quality, has propelled us as a household name in the online book publishing sector.</p>

         <p>Presently, the digital universe is flooded with eBook publishers in India who make tall claims just for the sake of pushing their sales volumes. Moreover, the practice of hiding damaging sub-clauses under fine prints is making things worse.</p>
           <div className=" row checklist mt-4">
              <div className="col-md-6">
             <span className="d-flex"><i className="fa fa-check"></i>  <h6 className="text-poppins ps-3 mt-2">Intellectual Rights</h6></span>
              </div>
              <div className="col-md-6">
              <span className="d-flex"><i className="fa fa-check"></i>  <h6 className="text-poppins ps-3 mt-2">Profit Sharing</h6></span>
              </div>
              <div className="col-md-6 mt-3">
              <span className="d-flex"><i className="fa fa-check"></i>  <h6 className="text-poppins ps-3 mt-2">Marketing Support</h6></span>
              </div>
              <div className="col-md-6 mt-3">
              <span className="d-flex"><i className="fa fa-check"></i>  <h6 className="text-poppins ps-3 mt-2">Book Interior & Cover</h6></span>
              </div>
              
             
              
           </div>  
           <AnimationOnScroll animateIn="animate__fadeIn animate__delay-0.2s"> 
           <img src={whyus3} alt="" className="img-fluid why-img-c"  />
        </AnimationOnScroll>
   </div>
           </div>
        </div>
    </div>

     
        
        
        </>
    )
}

export default Why_Us