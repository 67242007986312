import React from "react";

import { Link } from "react-router-dom";
import CountUp from 'react-countup';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import aboutpic from "../assets/img/home/about (2).png";
import shweta from "../assets/img/team/shweta.webp";
import chitra from "../assets/img/team/chitra.webp";
import neha from "../assets/img/team/neha.webp";
import anamika from "../assets/img/team/anamika.webp";
import saumya from "../assets/img/team/saumya.webp";
import Samiksha from "../assets/img/team/Samiksha.webp";
import Deepshikha from "../assets/img/team/Deepshikha.webp";
import vikash from "../assets/img/team/vikas.webp";
import deepti from "../assets/img/team/deepti.webp";

const About = () => {
    return (

       
        <>
         <div class="breadcumb-wrapper about-img" >
        <div class="container">
            <div class="breadcumb-content">
                <h2 class="breadcumb-title ps-4 pb-2">About Us</h2>
                <ul class="breadcumb-menu">
                    <li><Link to="/">Home /</Link></li>
                    <li >About Us</li>
                </ul>
            </div>
        </div>
    </div>
            <section className="about mt-4">
                <div className="container">
                <h1>ABOUT BFC PUBLICATIONS <br/>
                    <span className="sub-main">IF WRITING IS YOUR PASSION, PUBLISHING IS OURS.</span>
                    
                    </h1> 
              
                <div className="row align-items-center py-2">
         <div className="col-md-6">
                <p className="text-justify">
                BFC Publications has managed to bring together a group of young and dynamic professionals, all experts in their respective fields. The sole purpose of doing so is to build a platform that does justice to those who are passionate about telling stories but don't have the means to get them published.
                </p>
                <p className="text-justify">
                What most budding authors fail to realise is that a book is rated not just on how well it is written, but also on the response it garners. This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors, but also to educate them about the nuances of writing and publishing. Expanding a book's digital footprint is a conundrum that can be too much for first-timers to decipher, an aspect our digital marketing experts have got covered. So trust us when we tell you that we pull out all the stops to push your book's visibility.
                </p>
                {/* <Link to="" className="btn btn-custom">Read More</Link> */}
              </div>
                <div className="col-md-6">
                    <div className="img-box1">
                        <div className="img1"><img src={aboutpic} alt="About" className="img-fluid"/></div>
                      
                       
                        <div className="year-counter">
                        <AnimationOnScroll animateIn="animate__zoomIn animate__delay-0.2s">
                            <h3 className="year-counter_number "><span className="counter-number text-poppins"><CountUp end={15} delay={1}/>+</span></h3>
                            <p className="year-counter_text text-rale">Years Experience</p>
                            </AnimationOnScroll>
                        </div>
                       
                    </div>
                </div>
               
            </div>
                </div>
            </section>
<section className="my-5 bg-light-blue py-4">
<div className="container">
    <h1>THE PATH BREAKERS</h1>
  
    <div className="row team-grid align-items-stretch ">
        <div className="col-3  align-self-center">
        <div class="pic">
						<div class="stack twisted">
							<img src={vikash} alt=" " className="img-fluid"/>
						</div>
					</div>

        </div>

        <div className="col-9  text-justify">

         <h4 className="text-poppins text-red-c">Vikas Singh</h4>
              <p>Vice President</p>

              <p className="text-poppins text-black fs-13">With a Corporate experience of more than a Decade and Diverse Exposure of Automobiles, Consumer Durables, Education and Wealth Management, Vikas brings with him the Cutting Edge required to effectively manage different Strategic Business Units.</p>
              <p  className="text-poppins text-black fs-13">He has headed the Revenue Vertical since Inception and was instrumental in Designing, Testing & Modifying Working Processes and Strategies during the foundation year. Presently he works on Strategy Formation, Package Upgradation, Innovative Working Methods, Revenue Projections and a few other areas.</p>
              <p  className="text-poppins text-black fs-13">A die hard reader at heart, he loves being involved with Books and thus leaves no stone unturned to ensure Authors get the Best of everything when they come onboard with BFC.</p>
        </div>
    </div>
    <div className="row team-grid align-items-stretch  mt-5">
      

        <div className="col-9  text-justify">

         <h4 className="text-poppins text-red-c text-end">Deepti Bansal</h4>
              <p className="text-end">Asst. Vice President</p>

              <p className="text-poppins text-black fs-13">As Assistant Vice President, Deepti is responsible for supporting the company's strategies for growth and development. She oversees several different areas, such as day-to-day working, executes Revenue Strategies and works on Business Expansion.</p>
              <p  className="text-poppins text-black fs-13">She holds a Master’s degree in Finance & Marketing and has worked for over 6 years in the Financial Industry. The numerous hours she spent scanning and manipulating complex data, cultivated in her the skills needed to lead a group as dynamic as her Team.</p>
              <p  className="text-poppins text-black fs-13">“Publishing Consultants, however brilliant, need to be pointed in the right direction. Someone needs to take responsibility for extracting the best out of them.” she says.</p>
        </div>
        <div className="col-3  align-self-center">
        <div class="pic">
						<div class="stack twisted">
							<img src={deepti} alt=" " className="img-fluid"/>
						</div>
					</div>

        </div>
    </div>

    </div>




</section>

<section className="GO-GETTERS my-4">

    <h1>THE GO GETTERS !</h1>
<div class="team" id="team">
		<div class="container">
			
			<div class="team-grids row">
				<div class="col-md-3 team-grid">
                <div class="pic">
						<div class="stack twisted effect4">
							<img src={shweta} alt=" " className="img-fluid"/>
                            <span>Shweta is one of our most passionate Publishing Consultants with abundant marketing experience under her belt. She has been with the team for 3-odd years and has helped numerous Authors publish and market their books.<br/>
                          
                            "A book is as good as its cover, at least in this digital age", she opines. "Yes, this contradicts an old saying about books and not judging on covers, but like it or not, covers go a long in selling books in this age of trending hashtags."


                            </span>
                           
						</div>
                        
					</div>
					<h4 className="text-poppins">Shweta Mishra</h4>
                    <p>Sr. Publishing Consultant</p>
                   
				</div>
				<div class="col-md-3 team-grid">
					<div class="pic">
						<div class="stack twisted effect4">
						<img src={chitra} alt=" " className="img-fluid"/>
                        <span>With more than 7 years of Quality Control and Customer Support experience under her belt, Chitra is a master in outlining publishing strategies.<br/>
                        She understands the self-publishing space pretty well and makes sure the strategies she outlines are in line with Author inputs and priorities.
                      


                            </span>
						</div>
					</div>
					<h4 className="text-poppins">Chitra Pal</h4>
                    <p>Sr. Publishing Consultant</p>
				</div>
				<div class="col-md-3 team-grid">
					<div class="pic">
						<div class="stack twisted effect4">
							<img src={neha} alt=" " class="img-fluid"/>
                            <span>Much like her colleagues, Neha is a Publishing Consultant and a valued part of Team BFC. She is thorough and unrelenting when it comes to delivering Author satisfaction.<br/>
                            "The job warrants that I interact with Authors, understand their needs and offer solutions to address those needs. This becomes easier when you are aware of the process,” she clarifies.


                            </span>
						</div>
					</div>
					<h4 className="text-poppins">Neha Sahu</h4>
                    <p> Publishing Consultant</p>
				</div>
                <div class="col-md-3 team-grid">
					<div class="pic">
						<div class="stack twisted effect4">
							<img src={anamika} alt=" " class="img-fluid"/>
                            <span>Anamika works as a Publishing Consultant with Team BFC. She is required to handhold Authors looking to get published.<br/>
                            “I usually approach the task by explaining in detail, the services Authors can avail and the marketing tools that can be deployed to advertise their beloved masterpieces,” she explains in her usual optimistic tone.
                      


                            </span>
						</div>
					</div>
					<h4 className="text-poppins">Anamika Singh</h4>
                    <p>Publishing Consultant</p>
				</div>

				<div class="clearfix"> </div>
			</div>
            <div class="team-grids row justify-content-center mt-4">
				<div class="col-md-3 team-grid">
					<div class="pic">
						<div class="stack twisted effect4">
							<img src={saumya} alt=" " className="img-fluid"/>
                            <span>Saumya is a postgraduate and an MBA who excels in outlining marketing strategies. So far, she has helped several newbie authors publish their books and has been lauded by many for the extra mile she walks to market the book.<br/>
                            “Guilty as charged,” she says with a sheepish grin when asked about her tendency to go out of the way to help first-timers. “I make it a point to explain to the authors in detail, the services and marketing tools they can leverage to showcase their books appropriately. 


                            </span>
						</div>
					</div>
					<h4 className="text-poppins">Saumya Khajanchi</h4>
                    <p>Publishing Consultant</p>
				
				</div>
				<div class="col-md-3 team-grid">
					<div class="pic">
						<div class="stack twisted effect4">
						<img src={Samiksha} alt=" " className="img-fluid"/>
                        <span>Samiksha is an MBA in Finance & Marketing who excels in outlining marketing strategies. So far, she has aided several newbie authors in publishing their books and has been lauded by many for the extra mile she tends to walk to guide them.<br/>
                        "I make it a point to explain to the authors in detail, the services and marketing tools they can leverage to showcase their books appropriately. This usually helps them prioritise and determine what they want", she explains.
                      


                            </span>
						</div>
					</div>
					<h4 className="text-poppins">Samiksha Singh</h4>
                    <p>Publishing Consultant</p>
				</div>
				<div class="col-md-3 team-grid">
					<div class="pic">
						<div class="stack twisted effect4">
							<img src={Deepshikha} alt=" " class="img-fluid"/>
                            <span>Deepshikha is a commerce graduate and an avid reader. Much like her comrades, she is required to handhold Authors looking to publish their books. “I usually approach the task by explaining the services and marketing strategies that authors can deploy to advertise their beloved masterpieces”, she says<br/>
                          


                            </span>
						</div>
					</div>
					<h4 className="text-poppins">Deepshikha Mishra</h4>
                    <p> Publishing Consultant</p>
				</div>
               
			
			</div>
		</div>
	</div>
</section>
            



        </>
    )
}
export default About;