import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import platform1 from "../assets/img/distribution-platform/1.png";
import platform2 from "../assets/img/distribution-platform/2.png";
import platform3 from "../assets/img/distribution-platform/3.png";
import platform4 from "../assets/img/distribution-platform/4.png";
import platform5 from "../assets/img/distribution-platform/5.png";
import platform6 from "../assets/img/distribution-platform/6.png";
import platform7 from "../assets/img/distribution-platform/7.png";
import platform8 from "../assets/img/distribution-platform/8.png";

const Disribution =()=>

{
    return(

        <>
        
        <div className="container dist">
            <h1>OUR DISTRIBUTION PLATFORMS</h1>
            <Carousel interval={3000}>
    <Carousel.Item>
          {/* <Carousel interval={3000} autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false}> */}
            <div className="row p-5 mb-4">
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform1} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform2} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform3} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform4} className="img-fluid" />
                </div>
              </div>
            </div>
</Carousel.Item>
<Carousel.Item>
            <div className="row p-5 mb-4">
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform5} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform6} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform7} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="distribution-logo">
                  <img src={platform8} className="img-fluid" />
                </div>
              </div>
            </div>
            </Carousel.Item>

          </Carousel>
        </div>
     

        
        
        </>
    )
}

export default Disribution