import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaCheck, FaTimes} from 'react-icons/fa';
import 'react-tabs/style/react-tabs.css';
const Ebook =() =>{
    return(
        <>
        
        <div class="breadcumb-wrapper ebook-img" >
        <div class="container">
            <div class="breadcumb-content">
                <h2 class="breadcumb-title ps-4 pb-2">E-Book</h2>
                <ul class="breadcumb-menu">
                    <li><Link to="/">Home /</Link></li>
                    <li >E-Book</li>
                </ul>
            </div>
        </div>
    </div>
<section className="">

<div className="container-fluid">
        <h1>PUBLISHING PACKAGES</h1>
          <div className="row pt-3">
           
          </div>
              <Tabs>
              <div className="row justify-content-center">
            <div className="col-md-3 pb-4">
                <TabList>
                  <Tab>Paperback</Tab>
                  <Tab>eBook</Tab>
                </TabList>
                </div>
                </div>
              <div className="row bg-packages">
              <div className="col-md-12">
                <TabPanel>
                <div id="Paperback">
                <div class="pricing-table d-flex">
                  <div class="pricing-card paperback-wd plans-shadow">
                    <h3 class="pricing-card-header">Feature Description</h3>
                    <ul className="ps-0">
                      <li class="package-title pt-5 mt-3"> Editing Services</li>
                       <li>Format Editing</li>
                       <li>Proof Reading</li>
                       <li>Cover Design</li>
                      <li className="package-title">ISBN Allocation</li>
                      <li className="package-title"> Marketing your Masterpiece</li>
                       <li>Online Listing & Distribution</li>                    
                    </ul>
                  </div>

                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Essential</h3>
                    <div class="price"><span>₹6,699</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Basic  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes</li>
                     
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Regular</h3>
                    <div class="price"><span>₹13,999</span>+ GST</div>
                    <ul  className="ps-0">
                     <li><FaCheck className="ms-2 pi"/> Yes (Two Rounds) </li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Basic  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes</li>
                     
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Superior</h3>
                    <div class="price"><span>₹23,499</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Three Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Premium  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes</li>
                    
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Premium</h3>
                    <div class="price"><span>₹47,499</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes (Three Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Three Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Premium  (Three Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes</li>
                
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Elite</h3>
                    <div class="price"><span>₹73,499</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes (Three Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Three Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Premium  (Three Rounds)</li>
                      <li className="package-title"> Yes</li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes</li>
                     
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                </div>
              </div>
                </TabPanel>
                <TabPanel>
                <div id="eBook">
                <div class="pricing-table d-flex">
                  <div class="pricing-card ebook-wd plans-shadow">
                    <h3 class="pricing-card-header">Feature Description</h3>
                    <ul  className="ps-0">
                      <li class="package-title pt-5 mt-3">Publishing Support</li>
                       <li>Content Uploading Allowed - Unlimited</li>
                      <li className="package-title">Editing Services</li>
                       <li>Format Editing (Upto 100 Pages)</li>
                       <li>Proof Reading</li>
                       <li>Cover Design</li>
                      <li className="package-title">ISBN Allocation</li>
                     
                    </ul>
                  </div>

                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Essential</h3>
                    <div class="price"><span>₹3,199</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes</li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaTimes className="ms-2 pi-n"/>No</li>
                       <li><FaTimes className="ms-2 pi-n"/>No</li>
                      <li className="package-title"> Yes</li>
                    
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Regular</h3>
                    <div class="price"><span>₹6,499</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes </li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/> Basic (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                    
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Superior</h3>
                    <div class="price"><span>₹9,499</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes </li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Basic  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                    
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>

                  <div class="pricing-card plans-shadow">
                    <h3 class="pricing-card-header">Premium</h3>
                    <div class="price"><span>₹12,999</span>+ GST</div>
                    <ul  className="ps-0">
                       <li><FaCheck className="ms-2 pi"/>Yes </li>
                      <li className="package"></li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/>Yes (Two Rounds)</li>
                       <li><FaCheck className="ms-2 pi"/> Premium  (Two Rounds)</li>
                      <li className="package-title"> Yes</li>
                     
                    </ul>
                    {/* <a href="#" class="custom-btn">GET NOW</a> */}
                  </div>
                </div>
              </div>
                </TabPanel>
                </div>
                 </div>
              </Tabs>
             
            </div>
</section>

        </>

    )
}
export default Ebook;