import React from "react";
import { Link } from "react-router-dom";
import service1 from "../assets/img/home/about_2_3.jpg";
import service2 from "../assets/img/home/design.jpg";
import service3 from "../assets/img/home/marketing.jpg";
import service4 from "../assets/img/home/distribution.jpg";
import service5 from "../assets/img/home/support.jpg";
import service6 from "../assets/img/home/as.jpg";


const Service_We_Offer = () =>
{
    return(
    <>
   
    <div className="container">
        <h1>SERVICES WE OFFER</h1>
        <div className="row py-5">
     
 
    <div className="col-md-4 px-0">
    <div class="card border-0">
      <div class="card-img">
        <div class="flip-card-inner ">
          <div class="flip-card-front p-2">
            <img src={service1} alt=""/>
          </div>
          <div class="flip-card-back p-3">
           <p>This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors</p>
          </div>
        </div>

      </div>

      <div class="card-body ">
        <h6> Editorial Services</h6>
        
      </div>
    </div>
    </div>
    <div className="col-md-4 pe-0">
    <div class="card border-0">
      <div class="card-img">
        <div class="flip-card-inner">
          <div class="flip-card-front p-2">
            <img src={service2} alt=""/>
          </div>
          <div class="flip-card-back p-3">
           <p>This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors</p>
          </div>
        </div>

      </div>

      <div class="card-body">
        <h6>Designing Services</h6>
      
      </div>
    </div>
    </div>
    <div className="col-md-4 pe-0">
    <div class="card border-0">
      <div class="card-img">
        <div class="flip-card-inner">
          <div class="flip-card-front p-2">
            <img src={service3} alt=""/>
          </div>
          <div class="flip-card-back p-3">
           <p>This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors</p>
          </div>
        </div>

      </div>

      <div class="card-body">
        <h6>Marketing Services</h6>
       
      </div>
    </div>
    </div>
    <div className="col-md-4 ps-0 mt-3">
    <div class="card border-0">
      <div class="card-img">
        <div class="flip-card-inner">
          <div class="flip-card-front p-2">
            <img src={service4} alt=""/>
          </div>
          <div class="flip-card-back p-3">
           <p>This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors</p>
          </div>
        </div>

      </div>

      <div class="card-body">
        <h6>  Distribution Services</h6>
       
      </div>
    </div>
    </div>
    <div className="col-md-4 pe-0 mt-3">
    <div class="card border-0 ">
      <div class="card-img ">
        <div class="flip-card-inner">
          <div class="flip-card-front p-2">
            <img src={service5} alt=""/>
          </div>
          <div class="flip-card-back p-3">
           <p>This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors</p>
          </div>
        </div>

      </div>

      <div class="card-body">
        <h6> Author Support</h6>
        
      </div>
    </div>
    </div>
    <div className="col-md-4 pe-0 mt-3">
    <div class="card border-0 ">
      <div class="card-img ">
        <div class="flip-card-inner">
          <div class="flip-card-front p-2">
            <img src={service6} alt=""/>
          </div>
          <div class="flip-card-back p-3">
           <p>This is why our publishing consultants are groomed not just to resolve the queries of aspiring authors</p>
          </div>
        </div>

      </div>

      <div class="card-body">
        <h6> Author Support</h6>
        
      </div>
    </div>
    </div>
    <div className="col-md-12 text-center pt-4">
        <Link to="" className="btn btn-custom"> Explore More</Link>
    </div>
  </div>
</div>

    </>
    )
}

export default Service_We_Offer