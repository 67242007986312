import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./pages/header";
import "../src/assets/css/style.css";
import 'animate.css';
import Footer from "./pages/footer";
import Home from "./pages/home";
import About from "./pages/about";
// import PublishingPackage from "./pages/publishing-package";
import Ebook from "./pages/ebook";
// import PaperBack from "./pages/paper-back";
// import Editorial_Services from "./pages/editorial-services";
// import DistributionServices from "./pages/distribution-services";
// import Authorsupport from "./pages/author-support";
// import MarketingServices from "./pages/marketing-services";
// import Contact_Us from "./pages/contact-us";

function App() {
  return (
    <>
       <BrowserRouter>
       <Header />
       <Routes>
        <Route path="/" element={<Home />}></Route>
         <Route path="/about" element={<About />}></Route>    
        <Route path="/ebook" element={<Ebook />}></Route>
          {/*  <Route path="/publishing-package" element={<PublishingPackage />}></Route>
        <Route path="/paper-back" element={<PaperBack />}></Route>
        <Route path="/editorial-services" element={<Editorial_Services />}></Route>
        <Route path="/distribution-services" element={<DistributionServices />}></Route>
        <Route path="/author-support" element={<Authorsupport />}></Route>
        <Route path="/marketing-services" element={<MarketingServices />}></Route>
        <Route path="/contact-us" element={<Contact_Us />}></Route> */}
        
        
      </Routes>
      <Footer />
       </BrowserRouter>
    </>
  );
}

export default App;
