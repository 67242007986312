import React from "react";
import klfv from "../assets/img/klf/klf2.gif";
import bookstall from "../assets/img/klf/books.png";
import lounge from "../assets/img/klf/lounge.png";
import Sessions from "../assets/img/klf/interview.png";
import Gallery from "../assets/img/klf/image-gallery.png";

const Klf =()=>
{
    return(

        <>
        
        <div className="container px-0">
          <div className="row align-items-stretch">
            <div className="col-md-6 align-self-center">            
        <img src={klfv} alt="" className="img-fluid klf-img" />
            </div>
            <div className="col-md-6 ">
            <div className="klf-caption">
                <h3>BFC PUBLICATIONS PRESENTS</h3>
                <h2>KOSHALA LITERATURE FESTIVAL 2022</h2>
                <p className="mb-4">Held between 4-6 November 2022, KLF-Awadh was a three-day long celebration revolving around the rich heritage of Awadh, featuring the biggest names in the fields of Arts, Literature, Music, Filmmaking, and much more.<a href="#">Read more</a></p>
             
              </div>

              <div>
              <div className="row ">
                <div className="col-md-6 pb-3 ">
                  <a href="#" className="ba">
                    <span>
                    <div className="klf-link d-flex klf-icon_box align-items-stretch">
                      <img src={bookstall} className="img-fluid" />
                      <p className="align-self-center">Book Stall</p>
                    </div>
                    </span>
                  </a>
                </div>
                <div className="col-md-6 pb-3">
                  <a href="#" className="ba">
                    <span>
                    <div className="klf-link d-flex">
                      <img src={lounge} className="img-fluid" />
                      <p>BFC Lounge</p>
                    </div>
                    </span>
                  </a>
                </div>
                <div className="col-md-6">
                  <a href="#" className="ba">
                    <span>
                    <div className="klf-link d-flex">
                      <img src={lounge} className="img-fluid" />
                      <p>KLF Sessions</p>
                    </div>
                    </span>
                  </a>
                </div>
                <div className="col-md-6 ">
                  
                  <a href="#" className="ba">
                  <span>
                    <div className="klf-link d-flex">
                      <img src={Gallery} className="img-fluid" />
                      <p>Gallery</p>
                    </div>
                    </span>
                  </a>
                
                </div>
              </div>
              </div>
               
            </div>
         
          </div>
        </div>
        
        
        </>
    )
}
export default Klf