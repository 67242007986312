import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { FaBookOpen } from 'react-icons/fa';

const Publishing_Now =()=>  {

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
     <a href="#"onClick={handleShow} className="btn btn-custom"> <FaBookOpen className='pe-2 fa-2x'/>Start Publishing Now</a>
    

  <Modal show={show} onHide={handleClose} centered>
    <Modal.Header className='bg-light'>
      <Modal.Title className='text-uppercase'>Start Publishing Now </Modal.Title>
    <a href='#'onClick={handleClose} >  <i class="fa fa-times fa-2x"></i></a>
    </Modal.Header>
    <Modal.Body>
   <form action="" className='p-3'>

   <div class="form-group has-search mb-3">
    <label htmlFor="name">Your Name</label>
    <i class="fa fa-user form-control-feedback"></i>
    <input type="text" class="form-control" placeholder="Your Name"/>
  </div>
  <div class="form-group has-search mb-3">
    <label htmlFor="name">Email</label>
    <i class="fa fa-envelope-o form-control-feedback"></i>
    <input type="text" class="form-control" placeholder="Email"/>
  </div>
  <div class="form-group has-search mb-3">
    <label htmlFor="name">Contact Number</label>
    <i class="fa fa-phone form-control-feedback"></i>
    <input type="text" class="form-control" placeholder="Contact Number"/>
  </div>
  <div class="form-group text-end mt-2">
  <a href="#" className="btn btn-custom py-2 ">Submit</a>
  </div>
   </form>
    </Modal.Body>
    
  </Modal>
  </>
  );
}

export default Publishing_Now;