import React from "react";
import {  FaYoutube,FaFacebookF,FaLinkedinIn,FaTwitter,FaInstagram,FaEnvelope,FaMapMarkerAlt,FaPhoneAlt,FaAngleRight } from 'react-icons/fa';
import { Link } from "react-router-dom";
import logo from "../assets/img/logo/bfc-logo.png";
function Footer (){
return(
<>
<section className="pt-4 footer-section mt-5">
<footer>
    <div class="row footer">
      <div class=" col-md-4 brand">
        <div class=""> <img src={logo} alt='logo' className="img-fluid foot-logo"/></div>
        <p className="text-justify text-rale">Since its inception, BFC Publications has been fast gaining mileage over other self publishers in India. If online comparisons are to be believed, even the most prominent book publishers</p>
        <div class="social">
          <Link to="#twitter" class="social-icon">
           <FaFacebookF/>
          </Link>
          <Link to="#fb" class="social-icon">
          <FaTwitter/>
          </Link>
          <Link to="#youtube" class="social-icon">
            <FaInstagram/>
          </Link>
          <Link to="#youtube" class="social-icon">
            <FaLinkedinIn/>
          </Link>
          <Link to="#youtube" class="social-icon">
            <FaYoutube/>
          </Link>
          <Link to="#youtube" class="social-icon">
            <FaEnvelope/>
          </Link>
        </div>
      </div>
      <ul class="col-md-2 foot-link ps-4">
       <li> <div class="h5"> OUR SERVICES</div></li>
       <li> <Link to="#"><FaAngleRight/> Editorial Services</Link></li>
       <li> <Link to="#"><FaAngleRight/>Designing Services</Link></li>
       <li> <Link to="#"><FaAngleRight/>Marketing Services</Link></li>
       <li> <Link to="#"><FaAngleRight/>Distribution Services</Link></li>
       <li> <Link to="#"><FaAngleRight/>Author Support</Link></li>
      </ul>
      <ul class="col-md-2 foot-link pe-0 mx-15">
       <li> <div class="h5"> QUICK LINKS</div></li>
       <li>  <Link to="#"><FaAngleRight/>Packages</Link></li>
       <li> <Link to="#"><FaAngleRight/>Services</Link></li>
       <li> <Link to="#"><FaAngleRight/>eBook Publisher</Link></li>
       <li> <Link to="#"><FaAngleRight/>Book Publisher</Link></li>
       <li> <Link to="#"> <FaAngleRight/>Book Store</Link></li>
      </ul>
      <ul class="col-md-2 foot-link ps-0">
      <li> <div class="pb-4"></div></li>
        <li className="mt-2">  <Link to="#" ><FaAngleRight/>Royalty Calculator</Link></li>
        <li> <Link to="#"><FaAngleRight/>Author Dashboard</Link></li>
        <li>  <Link to="#"><FaAngleRight/>FAQs</Link></li>
        <li> <Link to="#"><FaAngleRight/>Print On Demand</Link></li>
        <li> <Link to="#"><FaAngleRight/>Blog</Link></li>
      </ul>
      <ul class="col-md-2 foot-link ps-0 add">
     <li>  <div class="h5">OFFICE ADDRESS</div></li>
     <li><a href="#"><FaMapMarkerAlt className="me-2 text-red-c"/>BFC Publications Pvt Ltd,<br/>CP-61, Viraj Khand, Gomti Nagar,<br/>Lucknow, Uttar Pradesh 226010</a></li>
     <li><a href="#"><FaPhoneAlt className="me-2 text-red-c"/>+915223514141</a></li>
     <li><a href="#"><FaEnvelope className="me-2 text-red-c"/>support@bfcpublications.com</a></li>
        
      </ul>
    </div>

    <section class="copyright">
        <div className="row">
            <div className="col-md-4">
            <p className="text-white"><span>©</span>  All Rights Reserved.BFC Publications</p>
            </div>

            <div className="col-md-8 text-end">
            <p>
        <Link to="#">Terms and Conditions</Link>
        |
        <Link to="#privacy">Privacy Policy</Link>
        |
        <Link to="#terms"> Legal Disclaimer</Link>
        |
        <Link to="#security">Refund and Cancellation Policy</Link>
      </p>
            </div>
        </div>
      
     
    </section>
  </footer>
  </section>
</>
);
}
export default Footer;