import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import line2 from "../assets/img/testimonial/line_2.png";
import line1 from "../assets/img/testimonial/line_1.png";
import quete from "../assets/img/testimonial/quote_left_2.svg";
import Samarjeetsingh from "../assets/img/testimonial/Samarjeetsingh.webp";
import subhash from "../assets/img/testimonial/subhash.webp";
import adarsh from "../assets/img/testimonial/adarsh.webp";
import nitin from "../assets/img/testimonial/nitin.webp";
import sadmin from "../assets/img/testimonial/sadmin.webp";
import roshan from "../assets/img/testimonial/roshan.webp";
import priya from "../assets/img/testimonial/priya.webp";
import shweta from "../assets/img/testimonial/shweta.webp";
import KaliDasPandey from "../assets/img/testimonial/KaliDasPandey.webp";
import ManayaHarsha from "../assets/img/testimonial/ManayaHarsha.webp";
import VaradAgrawal from "../assets/img/testimonial/VaradAgrawal.webp";
import Ashokauthor from "../assets/img/testimonial/Ashok-author.webp";
import CB from "../assets/img/testimonial/CB-Sharma.webp";
import mahesh from "../assets/img/testimonial/mahesh-bhatt.webp";
import pradeep from "../assets/img/testimonial/pradeep.webp";
import sharad from "../assets/img/testimonial/sharad-singh.webp";
import { FaStar } from 'react-icons/fa';
const Testimonial = () => {
return (
<>
<div className="container ">
  <h1>APPLAUSES</h1>
  <div class="shape-mockup1 jump  text-end" ><img src={line2} alt="shape" className=""/></div>
  <Carousel interval={3000}>
   
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={Samarjeetsingh} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">It was a really wonderful experience which I have gone through with BFC Publications and special thanks to my Project Manager as I really appreciate the way she worked in a professional manner and patiently dealt with new authors like me and it astonished me that the outcome and quality of my book was much more than I expected. I will be very soon coming up with more of my books in 2021.  </p>
              <div className="text-center">
                <h5 class="box-title">Samarjeet Singh</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={subhash} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">BFC Publications Private Limited, Lucknow is one of the renowned publications of India that have published my two books namely - Gustakhi and Preyasi. I express my gratitude towards my Publishing Consultant for all his dedicated help and support on this project and also my Project Manager whose experience and knowledge in the publishing field was indispensable.</p>
              <div className="text-center">
                <h5 class="box-title">Subhash Kumar</h5>
                <div class="testi-box_review">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={adarsh} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">It was nice to come in contact with BFC Publications, they assisted me in all possible ways, handling all queries and grievances at their topmost priority. I am grateful to my Project Manager for her consistent guidance and humility throughout this journey. During this pandemic time, she was always available virtually to help me with everything I needed for my book. I look forward to work in association with BFC Publications again for my new literary works. </p>
              <div className="text-center">
                <h5 class="box-title">Adarsh Singh</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={nitin} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">Experience is very good. The BFC Publications team delivered everything that was promised. As a first-time author, I could find nothing to criticize.</p>
              <div className="text-center">
                <h5 class="box-title">Nitin Sharma</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={sadmin} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">My book came out better than I have imagined. The cover, layout, interior, everything was just spot on, especially the way my project manager guided me throughout the procedure of publishing, that's what a first time author like me needs the most. I want to thank BFC publications and the entire team for their constant support and dedicated work. </p>
              <div className="text-center">
                <h5 class="box-title">Sadmin Sadique</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={roshan} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">First of all, I want to thank all the BFC Publications team members for giving their hands to make my dream come true. As it was my first attempt to write a book, I really appreciate your good rapport with the authors and the publication's neat and rapid process.</p>
              <div className="text-center">
                <h5 class="box-title">Roshan Pandey</h5>
                <div class="testi-box_review">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={priya} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">As an author, I can say that I am glad and satisfied with the working style of BFC Publications. The assigned project manager is so hardworking and sincere. The work is very perfect overall. </p>
              <div className="text-center">
                <h5 class="box-title">Priya Singh</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={shweta} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">BFC Publications is one of the best publications in the market. They gave good facilities to the author as per their need. The work done in the book is incredible, which encourages me to publish more books from BFC Publications. They have made a fabulous design for the cover of the book, and the interior matter is likewise in effect. If I have to grade this publication, I would like to give 9 out of 10 for their immense support and smooth working.</p>
              <div className="text-center">
                <h5 class="box-title">Shweta Gaur</h5>
                <div class="testi-box_review">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={KaliDasPandey} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">My book came out better than I had imagined. The cover, layout, interior, everything was awesome. Bravo!! BFC. My name has also been added to the history of the long-grossing film 'Sholay' in the 70s. This is the gift of the BFC Publication. </p>
              <div className="text-center">
                <h5 class="box-title">Kali Das Pandey (Film Journalist/Advocate)</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={ManayaHarsha} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">BRight from the first talks with the consultant to the publishing part, my experience with BFC publications has been a happy one. From editing to designing everything has gone smoothly. My Project Manager has been extremely helpful in the entire process. I'm glad I made a right choice. A happy Author.</p>
              <div className="text-center">
                <h5 class="box-title">Manaya Harsha</h5>
                <div class="testi-box_review">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={VaradAgrawal} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">I would definitely endorse BFC publications because it is a team of professionals . Your services are really nice and deserve a five star. My queries were very well entertained and I am satisfied with the work. </p>
              <div className="text-center">
                <h5 class="box-title">Varad Agrawal</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={Ashokauthor} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">I had a very good experience with BFC Publications, for the publication of my book, the response was always given in a transparent manner for all my queries since it was my first book.</p>
              <div className="text-center">
                <h5 class="box-title">Ashok Kumar</h5>
                <div class="testi-box_review">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={CB} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">Thank you team BFC. Only you guys could have pulled this off in time. You have earned a repeat customer. </p>
              <div className="text-center">
                <h5 class="box-title">C.B. Sharma</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={mahesh} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">बीएफसी पब्लिकेशंस का कार्य सहयोग पूर्ण रहना पुस्तक के मुख्य पृष्ठ को आकर्षित करता है। काव्य को ठीक तरह से छापा गया है। सहयोग पूर्ण वातावरण से ही पुस्तक को छापने में सफलता मिली है। पुस्तक की एडिटिंग / प्रिंटिंग / प्रूफरीडिंग अच्छी है।</p>
              <div className="text-center">
                <h5 class="box-title">महेश चंद्र भट्ट</h5>
                <div class="testi-box_review">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="row mt-n2">
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={pradeep} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">BFC Publications is undoubtedly a very reliable publishing platform. The team is extremely helpful and well coordinated. I am especially happy with the quality of editing, and my book cover. Job well-done team. </p>
              <div className="text-center">
                <h5 class="box-title">Pradeep Chaturvedi</h5>
                <div class="testi-box_review ">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="testi-box">
            <div class="testi-box_img">
              <img src={sharad} alt="Avater"/>
              <div class="testi-box_quote"><img src={quete} alt="quote"/></div>
            </div>
            <div class="testi-box_content">
              <p class="testi-box_text">यह पुस्तक बीएफसी पब्लिकेशंस द्वारा प्रकाशित हुई है। इसका पेपर वर्क एवं प्रकाशन कार्य उत्तम है। कवर पृष्ठ भी सुंदर रूप से बनाया गया है। अनसुलझे प्रश्न (कथा संग्रह), जीवन संघर्ष (उपन्यास), लालसा, मेरी स्मृतियां ( काव्य संग्रह ) के पश्चात मेरी एक और त्वरित प्रकाशित पुस्तक अहसास (कथा संग्रह) आपके समक्ष प्रस्तुत है। यह पुस्तक आपको अमेजान, फ्लिपकार्ट आदि पर मिल जायेगी। पढ़कर अपनी प्रतिक्रिया दें।</p>
              <div className="text-center">
                <h5 class="box-title">शरद सिंह</h5>
                <div class="testi-box_review">
                  <FaStar className="star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                  <FaStar className="ms-1 star"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
  </Carousel>
  <div class="shape-mockup2 moving "><img src={line1} alt="shape"/></div>
</div>
</>
);
}
export default Testimonial;