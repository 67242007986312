import React,  { useState } from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/img/logo/bfc-logo.png";
import { NavLink } from "react-router-dom";
function Header (){
  const [scroll, setScroll] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
return(
<>
<Navbar expand="lg"  sticky="top"  className={scroll ? "header-shrink " : " custom-shadow"}>
  <Container fluid>
<Navbar.Brand href="/"><img src={logo} alt='logo' className="img-fluid logo"/></Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
    <ul className="navbar-nav main-menu ml-auto ">
      <li><NavLink to="/" activeClassName="nav-link ">Home</NavLink></li>
      <li><NavLink to="/about" activeClassName="nav-link ">About</NavLink></li>
      <li className="menu_has_children"><a href="/publishing-package">Packages</a>
      <ul className="sub-menu lst-0">
        <li><NavLink to="/eBook">eBook</NavLink></li>
        <li><NavLink to="/paper-back">Paperback</NavLink></li>
      </ul>
    </li>
    <li><NavLink to="/royalty-calculator">Royalty Calculator</NavLink></li>
    <li className="menu_has_children"><a href="#">Services</a>
    <ul className="sub-menu lst-0">
      <li><NavLink to="/editorial-services"> Editorial Services</NavLink></li>
      <li><NavLink to="/designing-services">Designing Services</NavLink></li>
      <li><NavLink to="/marketing-services">Marketing Services</NavLink></li>
      <li><NavLink to="/distribution-services"> Distribution Services</NavLink></li>
      <li><NavLink to="/author-support"> Author Support</NavLink></li>
    </ul>
  </li>
  <li><NavLink to="/author-dash" className="">Author Dashboard</NavLink></li>
  <li><NavLink to="/bookstore">Book Store</NavLink></li>
  <li><NavLink to="https://bfccapital.com/blog/" target="_blank">Blogs</NavLink></li>
  <li><NavLink to="/contact-us">Contact Us</NavLink></li>
  <li><a href="https://bfcpublications.klf.bfcpublications.com/">KLF</a></li>
</ul>

</Navbar.Collapse>
</Container>
</Navbar>

</>
);
}
export default Header;