import React from "react";
import { FaPencilAlt,FaFileSignature,FaCheckDouble ,FaPenFancy,FaPaintBrush,FaRupeeSign} from 'react-icons/fa';
import sm from "../assets/img/matrix/social-media.png"
const Matrix = () =>
{
    return(



        <>
               
      
       
        <div class="container ">
        <h1>OUR SELF PUBLISHING MATRIX</h1>
      
        <div class="row py-5">
        <div class="col-md-12">
            <div class="main-timeline">
                <div class="timeline">
                    <a href="#" class="timeline-content tc-1">
                        <div class="timeline-icon"><FaPencilAlt className="img"/></div>
                        <h3 class="title">Consultation & Registration</h3>
                        <p class="description">
                        Leverage the know-how of our expert consultants to identify the publishing solution that best suits your needs, or get one tailored. 
                        </p>
                    </a>
                </div>
                <div class="timeline">
                    <a href="#" class="timeline-content tc-2">
                        <div class="timeline-icon"><FaFileSignature className="img"/></div>
                        <h3 class="title">Contract Signing</h3>
                        <p class="description">
                        Enter into a contract with us to safeguard the intellectual rights of your manuscript. This also ensures that both parties, you and BFC uphold 
                        </p>
                    </a>
                </div>
                <div class="timeline">
                    <a href="#" class="timeline-content tc-3">
                        <div class="timeline-icon"><FaCheckDouble className="img"/></div>
                        <h3 class="title">Manuscript Submission</h3>
                        <p class="description">
                        Submit your manuscript with us to get the ball rolling. A dedicated publishing manager/consultant shall be assigned to you at this 
                        </p>
                    </a>
                </div>
                <div class="timeline">
                    <a href="#" class="timeline-content tc-4">
                        <div class="timeline-icon"><FaPenFancy className="img"/></div>
                        <h3 class="title">Proofreading and Format Editing </h3>
                        <p class="description">
                        Our team of proof-readers and copy-editors will get working on your book, fine-combing the manuscript for errors.  
                        </p>
                    </a>
                </div>
                <div class="timeline">
                    <a href="#" class="timeline-content tc-1">
                        <div class="timeline-icon"><FaPaintBrush className="img"/></div>
                        <h3 class="title">Designing</h3>
                        <p class="description">
                        Our graphic designers and illustrators go to work on the interior and cover of the book. This is done keeping in mind the brief provided 
                        </p>
                    </a>
                </div>
                <div class="timeline">
                    <a href="#" class="timeline-content tc-2">
                        <div class="timeline-icon"><img src={sm} alt="" className="img-fluid img" /></div>
                        <h3 class="title">Marketing & Online Distribution</h3>
                        <p class="description">
                        The team guides authors to make decisions based on prevalent e-marketplace and digital marketing trends. Special emphasis is laid 
                        </p>
                    </a>
                </div>
                <div class="timeline">
                    <a href="#" class="timeline-content tc-3">
                        <div class="timeline-icon"><FaRupeeSign className="img"/></div>
                        <h3 class="title">Royalty</h3>
                        <p class="description">
                        In BFC, authors have absolute freedom when it comes to determining the worth of their work, even if it is over and above the suggested price. 
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
        
        
        </>
    )
}

export default Matrix