import React,{useState} from "react";
import Carousel from 'react-bootstrap/Carousel';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Publishing_Now from "../components/start-publishing-now";
import label from "../assets/img/home/label.png";
import label1 from "../assets/img/home/label-2.png";
import label2 from "../assets/img/home/label-3.png";
import stand from "../assets/img/banner/stand.png";
import book1 from "../assets/img/home/books/book-1.png";
import book2 from "../assets/img/home/books/book-2.png";
import book3 from "../assets/img/home/books/book-3.png";
import book4 from "../assets/img/home/books/book-4.png";
import book5 from "../assets/img/home/books/book-5.png";
import book6 from "../assets/img/home/books/book-6.png";
import book7 from "../assets/img/home/books/book-7.png";
import book8 from "../assets/img/home/books/book-8.png";

import Service_We_Offer from "../components/service-we-offer";
import Matrix from "../components/matrix";
import Klf from "../components/klf";
import About_Bfc_Publications from "../components/about-bfc-pub";
import Why_Us from "../components/why-us";
import Plans from "../components/plans";
import Testimonial from "../components/testimonial";
import Disribution from "../components/distribution";

// import { FaCheck } from 'react-icons/fa';
const Home = () => {
 
return (
<>
<section className="home" id="home">
  <div className="container px-0">
    <div className="row  align-items-stretch ">
      <div className="col-md-6 ">
        <div className="content pt-5 ">
        <AnimationOnScroll animateIn="animate__fadeInLeft animate__delay-0.9s" >
          <h2 className="freshGlow">IF WRITING IS YOUR PASSION,</h2>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__flipInX animate__delay-1s">
          <h2 className="freshGlow">PUBLISHING IS OURS.</h2>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp animate__delay-0.9s">
            <p className="short-desc">Owing to the sheer weight of the processes involved, shortlisting a self book publisher in India can be overwhelming, especially for first-timers. That's what we are here for. So say goodbye to those online publishing troubles and allow us to craft your manuscript into a masterpiece. Don't you worry, we've got this covered.</p>
          </AnimationOnScroll>
          <div className="mb-4">
            <img src={label} alt="" className="img-fluid mx-90" />
            <img src={label1} alt="" className="ps-5 img-fluid max-125" />
            <img src={label2} alt="" className="ps-5 img-fluid max-125" />
          </div>
          <AnimationOnScroll animateIn="animate__fadeInDown animate__delay-0.5s">
           <Publishing_Now/>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="col-md-6 align-self-center">
        <Carousel interval={3000}>
          <Carousel.Item>
            <div className="swiper books-slider pt-5 mt-3">
              <div className="swiper-wrapper">
                <a href="#" className="swiper-slide"><img src={book1} alt="" /></a>
                <a href="#" className="swiper-slide"><img src={book2} alt="" /></a>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="swiper books-slider pt-5 mt-3">
              <div className="swiper-wrapper">
                <a href="#" className="swiper-slide"><img src={book3} alt=""/></a>
                <a href="#" className="swiper-slide"><img src={book4} alt=""/></a>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="swiper books-slider pt-5 mt-3">
              <div className="swiper-wrapper">
                <a href="#" className="swiper-slide"><img src={book5} alt=""/></a>
                <a href="#" className="swiper-slide"><img src={book8} alt=""/></a> 
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="swiper books-slider pt-5 mt-3">
              <div className="swiper-wrapper">
                <a href="#" className="swiper-slide"><img src={book7} alt=""/></a>
                <a href="#" className="swiper-slide"><img src={book6} alt=""/></a> 
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <img src={stand} className="img-fluid" alt="" />
      </div>
    </div>
  </div>
</section>
<section className=" py-5">
      <Klf/>
      </section>


      <section className="bg-light-blue py-3">
       <About_Bfc_Publications/>
      </section>

      <section className="py-4 service-we-offer">
       <Service_We_Offer/>
       
          </section>

          <section className="bg-light-blue py-4">
            <Matrix/>
          </section>

          <section className="pt-4">
          <Why_Us/>
          </section>
          <section className="packages bg-light-blue py-3 mt-n2 ">

<Plans/>

            </section>

            <section className="py-4 testi-s">

              <Testimonial/>
            </section>
            <section className="distribution-platforms">
              <Disribution/>
            </section>



</>
);
}
export default Home;